@import url("https://fonts.googleapis.com/css2?family=Inter&family=Roboto&display=swap");

body {
  margin: 0;
  font-family: "Inter", sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
}

code {
  font-family: "Inter", sans-serif;
}
.active {
  background: #fd939f;
  border-radius: 8px;
  font-weight: 600;
}
